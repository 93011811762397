<template>
  <component :is="loaded === false ? 'div' : 'div'">
    <b-card-actions
        ref="refreshCard"
        action-back
        action-save
        action-delete
        action-refresh
        @back="hRedirect('apps-experts-bookingtimes-list', { id: $route.params.expertid })"
        @delete="helperDeleteItem(`/experts/${$route.params.expertid}/bookingtimes/${$route.params.bookingtimeid}`, null, 'apps-experts-bookingtimes-list', { id: $route.params.expertid })"
        @save="updateItem"
        @refresh="getItem"
        no-body
        disable-loading-on-refresh
        :loaded.snyc="loaded"
        :title="currentItem.starttime + ' - ' + currentItem.endtime + cardTitle"
    >
    </b-card-actions>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
          @submit.stop.prevent="handleSubmit(updateItem)"
      >
        <b-card>
          <div class="mb-2">
            <b-form-checkbox
                v-model="currentItem.status"
                value="1"
                name="status"
                class="custom-control-success"
            >
              {{ $t('Active') }}
            </b-form-checkbox>
          </div>

          <hr/>

          <b-row>
            <col md="12" xl="12" class="mb-1 mt-1 ml-1"/>
            <b-form-group :label="$t('Choose a Weekday')" label-for="Weekday">
              <b-form-select v-model="currentItem.weekday" :options="options"/>
              <small v-if="validate && Object.keys(validation).includes('weekday')" class="text-danger"
              >{{ $t(validation.weekday[0]) }}</small>
            </b-form-group>
          </b-row>

          <b-row>
            <b-col md="6" xl="6" class="mb-1">
              <b-form-group label="Start Time" label-for="starttime">
                <validation-provider
                    vid="starttime"
                    #default="validationContext"
                    name="starttime"
                    rules="required"
                >
                  <cleave
                      id="starttime"
                      v-model="currentItem.starttime"
                      class="form-control"
                      :raw="false"
                      :options="timeOption.starttime"
                      placeholder="hh:mm"
                  />
                  <small v-if="validate && Object.keys(validation).includes('starttime')" class="text-danger"
                  >{{ $t(validation.starttime[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="6" class="mb-1">
              <b-form-group label="End Time" label-for="endtime">
                <cleave
                    id="endtime"
                    v-model="currentItem.endtime"
                    class="form-control"
                    :raw="false"
                    :options="timeOption.endtime"
                    placeholder="hh:mm"
                />
                <small v-if="validate && Object.keys(validation).includes('endtime')" class="text-danger"
                >{{ $t(validation.endtime[0]) }}</small>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-button
              class="mt-2"
              variant="success"
              type="submit"
          >
            {{ $t('Save') }}
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </component>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BAlert,
  BToast,
  BLink,
  BCard,
  BRow,
  BCol,
  BFormCheckbox,
  BFormTextarea,
  BCardText,
  BFormDatepicker,
  BFormCheckboxGroup,
  BFormFile,
  BAvatar,
  BMedia,
  BFormTimepicker,
  BFormSelect,

} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted, mounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, alphaNum, email } from '@validations'
import BCardActions from '@core/components/b-card-actions/BCardActions'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BCardText,
    BFormDatepicker,
    BFormCheckboxGroup,
    vSelect,
    BFormFile,
    BAvatar,
    BMedia,
    BFormSelect,
    Cleave,
    BCardActions,
    BFormTimepicker,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      selected: null,
      options: [
        {
          value: null,
          text: this.$i18n.t('Please select a Day')
        },
        {
          value: 1,
          text: this.$i18n.t('Sunday')
        },
        {
          value: 2,
          text: this.$i18n.t('Monday')
        },
        {
          value: 3,
          text: this.$i18n.t('Tuesday')
        },
        {
          value: 4,
          text: this.$i18n.t('Wednesday')
        },
        {
          value: 5,
          text: this.$i18n.t('Thursday')
        },
        {
          value: 6,
          text: this.$i18n.t('Friday')
        },
        {
          value: 7,
          text: this.$i18n.t('Saturday')
        },
      ],
      currentItem: {
        status: '',
        weekday: '',
        starttime: '',
        endtime: '',
      },

      timeOption: {
        starttime: {
          time: true,
          timePattern: ['h', 'm'],
        },
        endtime: {
          time: true,
          timePattern: ['h', 'm'],
        },
      },
      loaded: true,
      validation: {},
      validate: false,
      cardTitle: ""
    }
  },

  mounted() {
    this.getItem()
    this.$http.get(`/experts/${this.$route.params.expertid}`)
        .then(response => {
          this.cardTitle = " (" + response.data.firstname + " " +response.data.name + ")"
        })
  },
  methods: {
    getItem() {
      this.helperGetItem(`/experts/${this.$route.params.expertid}/bookingtimes/${this.$route.params.bookingtimeid}`)
    },
    updateItem() {
      this.$refs.refFormObserver.validate()
          .then((success) => {
            this.validation = {}
            this.validate = false
            if (success) {
              this.helperUpdateItem(
                  `/experts/${this.$route.params.expertid}/bookingtimes/${this.$route.params.bookingtimeid}`,
                  this.currentItem
              )
            } else {
              this.loaded = false
              setTimeout(() => {
                this.loaded = true
              })
            }
          })
    },

    getValidationState({
      dirty,
      validated,
      valid = null
    }) {
      return dirty || validated ? valid : null
    },
  },
}
</script>

<style>
</style>
